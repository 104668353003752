<template>
    <el-row justify="center" class="mt-50">
      <el-col :span="18">
        <el-row :gutter="50">
          <el-col :span="16">
            <el-row :gutter="20">
              <el-col :sm="18" :md="18" :lg="18">
                <el-card shadow="never" class="text-center">
                  <h4>Total Order</h4>
                  <h3 class="mt-5">{{  orders }}</h3>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      
    </el-row>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters('auth', ['authUser', 'loggedIn']),
  },
  data() {
    return {
      orders: [],
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    getOrderList() {
            axios.post('/api/web/user/profile').then((res) => {
              console.log('res', res);
              
                this.orders = res.data.data.orders;
            })
        },
  }
};
</script>

<style scoped>
.mt-50 {
  margin-top: 50px;
}
</style>
<style>
.user-menu .el-menu {
  border-right:  0px !important;
}
</style>
