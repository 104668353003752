<template>
    <div class="about">
      <div class="common-section-padding" style="background: #fff">
        <el-row justify="center" :gutter="10">
          <el-col :sm="18" :md="18" :lg="18">
            <h1>Contact US</h1>
            <br>
            <h3>{{ setting.setting.company_name }}</h3> <br>
            <h3>{{ setting.setting.company_phone_number }}</h3>
            <h3>{{ setting.setting.company_phone_number_two }}</h3> <br>
            <h3>{{ setting.setting.company_email }}</h3>
            <h3>{{ setting.setting.company_email_two }}</h3> <br>
            <h3>{{ setting.setting.company_address }}</h3>
            <br>
            <br>
           
          </el-col>
        </el-row>
      </div>
    </div>
<br>
    <div class="about">
      <div class="common-section-padding" style="background: #fff">
        <el-row justify="center" :gutter="10">
          <el-col :sm="18" :md="18" :lg="18" class="text-center">
           
            <div v-html="setting.setting.about_us"></div>
          </el-col>
        </el-row>
      </div>
    </div>
  
  </template>
  <script>
  import { mapGetters } from 'vuex';
  
  export default {
    name: "Contact",
    computed: {
      // ...mapGetters('auth', ['authUser', 'loggedIn']),
      ...mapGetters({
        setting: 'setting/get_setting',
      })
    },
  }
  </script>
  