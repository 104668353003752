<template>
  <div class="common-section-padding" v-loading="loading">
    <el-row justify="center">
      <el-col :sm="24" :md="24" :lg="24">
        <el-card v-if="product" shadow="never" class="border-0">
          <el-row :gutter="20">
            <el-col :sm="24" :md="12" :lg="12">
              <ProductGallery :images="product_images"/>
            </el-col>
            <el-col :sm="24" :md="12" :lg="12">
              <h2 class="mb-10">{{ product.name }}</h2>
              <p>Category: <a href="#">{{ product.category ? product.category.name : '-' }}</a></p>
              <br>
              <h4 class="mb-5">
                Price:
                <del v-if="product.discount > 0">৳ {{ product.price }}</del>
                <span v-else>৳ {{ product.price }}</span>
              </h4>

              <h4 v-if="product.discount > 0">
                Discount Price: ৳ {{ product.price - (product.price * (product.discount / 100)) }}
              </h4>

              <br>
              <p class="p" v-show="product.short_description" v-html="product.short_description"></p>
              <br>
              <div>
                <p v-if="sizies.length">Size:</p>
                <div style="margin-top: 20px">
                  <el-radio-group v-for="(size, index) in sizies" :key="index" v-model="product.attribute_value_id"
                    style="margin: 5px 0px;">
                    <el-radio v-if="size.id" :label="size.id" class="color-value mx-10" border>{{ size.value }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <br>
                <div v-if="colors.length">
                  <p>Color:</p>
                  <div style="margin-top: 20px">
                    <el-radio-group v-for="(color, index) in colors" :key="index" v-model="product.color_id"
                      style="margin: 5px 0px;">
                      <el-radio border v-if="color" :label="color.id" class="color-value mx-10">
                        {{ color.name }}
                      </el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
              <br>
              <el-input-number v-model="product.qty_num" :min="1" :max="10" @change="handleChange" />
              <br><br>
              <el-button size="large" type="primary" @click="addProductToCart(product)" round>Add To Cart</el-button>
              <el-button size="large" @click="buyNowProduct(product)" type="success" round>Buy Now</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <br>
    <el-row justify="center">
      <el-col :sm="24" :md="24" :lg="24">
        <el-card shadow="never" class="border-0">
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="Description" name="first">
              <div v-html="product.description"></div>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
    <br>
    <el-row justify="center" v-if="related_products.length">
      <el-col :sm="24" :md="24" :lg="24">
        <el-card shadow="never" class="border-0">
          <h3>Related Products</h3>
          <ProductsComponent :products="related_products" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { OrderMixins } from '@/mixins/OrderMixins';
import axios from 'axios';
import ProductsComponent from '@/components/ProductsComponent';
import ProductGallery from '@/views/product/ProductGallery';

export default {
  name: 'SingleProduct',
  title: 'Shopping',
  mixins: [OrderMixins],
  components: {
    ProductsComponent,
    ProductGallery,
  },
  data() {
    return {
      activeName: 'first',
      num: 1,
      rating: 5,
      related_products: [],
      slider_img: '',
      product: {
        attribute_value_id: null,
        qty_num: 1,
      },
      colors: [],
      sizies: [],
      product_images: [],
      loading: false,

    };
  },
  mounted() {
    this.getProduct();
    this.relatedProducts();
  },

  methods: {

    getDiscountPrice(price, discount) {
      // eslint-disable-next-line radix
      const sum = parseInt(price) + parseInt(discount);
      return sum;
    },
    productImage(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    addProductToCart(product) {
      this.$store.dispatch('shopingCart/addProductToCart', product);
    },
    relatedProducts() {
      axios.get(`/api/web/related/products/${this.$route.params.slug}`)
        .then(res => {
          this.related_products = res.data.data;
        });
    },
    getProduct() {
      this.loading = true;
      axios.get(`/api/web/products/${this.$route.params.slug}`)
        .then(res => {
          this.product = res.data.data.product;
          this.sizies = res.data.data.size;
          this.colors = res.data.data.colors;
          this.product.attribute_value_id = this.sizies[0]?.id;
          this.product.color_id = this.colors[0]?.id;
          this.product.qty_num = 1;
          this.loading = false;
          // let all_images = [];
          // all_images.push(res.data.data.product.thumbnail_image);
          // if (res.data.data.product.images) {
          //   res.data.data.product.images.forEach(image => {
          //     all_images.push(image);
          //   });
          // }

          this.product_images.push(res.data.data.product.thumbnail_image);
          if(res.data.data.product.varients){
          res.data.data.product.varients.map(item => {
            if(item.image) {
              this.product_images.push(item.image);
            }
          });
        }

        });
    },
    openImg(image) {
      this.slider_img = image;
    },
    handleChange(){

    },
    handleClick(){

    }
  },
};
</script>

<style scoped>
a {
  color: #000;
}

.product_image {
  width: 100%;
}

.color-value {
  text-transform: uppercase;
}

.product img {
  position: relative;
  display: inline-block;
}

.zoom-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: transform 0.5s ease;
}

.product:hover .zoom-layer {
  transform: scale(1.5);
}

.thumb_product_image {
  height: 100px;
  width: 100px;
  object-position: center;
  object-fit: cover;
  margin: 2px;
  /*border: 3px solid #eb4960;*/
}

.thambnail_slider div img {
  cursor: pointer;
}

.d-flex {
  flex-wrap: wrap;
}
</style>
